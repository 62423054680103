<template>
  <div>
    <form @submit.prevent="next">
      <div 
        class="input-container" 
        :class="{'input-container--border' : addBorder}"
      >
        <BaseInput
          ref="input"
          v-model="userInput"
          type="text"
          class="d-inline input"
          :class="{'input-center' : centerInput}"
          :placeholder="placeholder"
        />
      </div>
    </form>
  </div>
</template>

<script>
import BaseInput from '@mic/frontend/inputs/BaseInput'

export default {
  name: 'TextInput',

  components: {
    BaseInput
  },

  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Enter your business name'
    },
    addBorder: {
      type: Boolean,
      default: false
    },
    centerInput: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      isValid: false,
      result: this.value.result,
      userInput: ''
    }
  },

  watch: {
    userInput (val) {
      if (val) {
        this.isValid = true
      } else {
        this.isValid = false
      }

      this.submit()
    }
  },

  mounted () {
    this.focus()
  },

  methods: {
    focus () {
      const { input } = this.$refs
      if (input && input.$el) {
        input.$el.focus()
      }
    },

    next () {
      if (this.isValid) {
        this.$emit('autoprogress')
      }
    },

    submit () {
      this.$emit('input', {
        result: this.isValid ? this.userInput : undefined
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.input-container {
  width: 340px;
  height: 72px;
  box-shadow: unset;
  border: 1px solid #E5E5E5;
  border-radius: 6px;
}

.input {
  line-height: 16px;
}

@media (max-width: $mobile-breakpoint-max) {
  .input-container {
    width: 100%;
  }
}

</style>
