import { turnOverRanges } from '@/helpers/constants/turnover-ranges'
/**
 * Returns range object
 *
 * @param {Number}  value turnover input from user
 * @return {Object} object with range data that fits user input
 */
export default function (value) {
  const rangeData = turnOverRanges
  const userInput = Number(value)
  // user input find closest number - return found obj 
  const closest = rangeData.reduce(function (prev, curr) {
    return (Math.abs(curr.rangeHigh - userInput) < Math.abs(prev.rangeHigh - userInput) ? curr : prev)
  })
  // found object index inside arr of objects
  const index = rangeData.findIndex(item => item.rangeHigh === closest.rangeHigh)
  let result
  // higher input than found range number - move to next range
  // if last range do not move on one
  if (userInput >= closest.rangeHigh && index + 1 < rangeData.length) {
    result = rangeData[index + 1]
  } else {
    result = closest
  }
  return result
}
