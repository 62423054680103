<template>
  <div>
    <form @submit.prevent="next">
      <span v-if="!isValid && isDirty" class="error">
        {{ validationErrorMessage }}
      </span>
      <div 
        class="input-container" 
        :class="{'input-container--border' : addBorder}"
      >
        <span class="currency">£</span>
        <BaseInput
          ref="input"
          v-model="displayResult"
          :pattern="numericalInputPattern"
          inputmode="numeric" 
          type="text"
          class="d-inline input"
          :class="{'input-center' : centerInput}"
          :placeholder="placeholder"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import BaseInput from '@mic/frontend/inputs/BaseInput'
import { numericalInputPattern } from '@/helpers/constants/regex-patterns'

export default {
  name: 'GbpInput',

  components: {
    BaseInput
  },

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    value: {
      type: Object,
      required: true
    },

    placeholder: {
      type: String,
      default: 'Enter the desired amount'
    },
    addBorder: {
      type: Boolean,
      default: false
    },
    centerInput: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isDirty: false,
      isValid: false,
      result: this.value.result,
      format: new Intl.NumberFormat('en-GB').format,
      setIsDirtyDebounced: debounce(() => {
        this.isDirty = true
      }, 400),
      numericalInputPattern
    }
  },

  computed: {
    displayResult: {
      get () {
        return this.result ? this.format(this.result) : ''
      },
      set (result) {
        this.setIsDirtyDebounced()
        this.result = result.replace(/\D+/g, '')
        this.$nextTick(() => {
          this.validateInput()
        })
      }
    },

    min () {
      return this.data && this.data.min
        ? this.data.min
        : undefined
    },

    validationErrorMessage () {
      if (this.data && this.data.min) {
        return `Please enter at least £${this.format(this.data.min)}`
      } else {
        return `Please enter a valid amount`
      }
    }
  },

  mounted () {
    this.focus()
  },

  methods: {
    validateInput () {
      if (this.data.min) {
        this.isValid = parseInt(this.result) >= this.data.min
      } else {
        this.isValid = !Number.isNaN(this.result)
      }

      this.submit()
    },

    focus () {
      const { input } = this.$refs
      if (input && input.$el) {
        input.$el.focus()
      }
    },

    next () {
      if (this.isValid) {
        this.$emit('autoprogress')
      }
    },

    submit () {
      this.$emit('input', {
        result: this.isValid ? this.result : undefined
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  text-align: left;
}

.list-group-item {
  cursor: pointer;

  &:hover {
    background: $bionic-grey-300;
  }
}

.input-container {
  width: 340px;
  position: relative;
}

.input-container .currency {
  font-size: 32px;
  padding-left: $spacing-8;
  line-height: 80px - (12px * 2) - 6px; // height - padding - offset
  font-weight: 600;
  position:absolute;
  bottom: 10px;
}

.error {
  color: red;
  margin-bottom: 20px;
}

.input {
  line-height: 16px;
}

@media (max-width: $mobile-breakpoint-max) {
  .input-container .currency {
    font-size: 28px;
  }

  .input-container {
    line-height: 56px;
    font-size: 16px;
  }

  .error {
    font-size: 16px;
  }
}

</style>
