<template>
  <div class="item">
    <div class="title-wrapper">
      <span class="title">{{ address.title }}<span v-if="address.titleExtra" class="title-extra">, {{ address.titleExtra }}</span></span>
      <div v-if="address.address_snippet" class="address">
        {{ address.address_snippet }}
      </div>
    </div>
    <div>
      <img
        src="@/assets/icons/next-right-circle.svg"
        alt="caret right circle icon"
        class="postcode-lookup__item-icon"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyListItem',

  props: {
    address: {
      type: Object,
      required: true
    },
    filterString: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  min-height: 72px;
}

.title-wrapper {
  /* line-height: 18px; */
}

.title {
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  padding: 0;
}

.title-extra {
  font-weight: normal;
}

.address {
  padding: 0;
  font-size: 14px;
  line-height: 14px;
}

.postcode-lookup__item-icon {
  width: 28px;
  height: 28px;
  padding: 0;
}

.postcode-lookup__address-item {
  padding-left: 0 0 0 32px;
}

.timeago {
  margin: 0 32px 0 auto;
  text-align: right;
}
</style>
