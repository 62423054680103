export default function (question, answer) {
  if (question.feedbackMultiChoiceMessage && answer.result) {
    // find matching feedback from choice selected from obj 
    return question.choices.find(item => {
      if (item.customChoice) {
        return item.customChoice === answer.result
      } else {
        return item.choice === answer.result
      }
    }).feedback
  } else {
    return null
  }
}
