<template>
  <div class="search-company">
    <form @submit.prevent>
      <div
        class="input-container input-container--border"
        :class="{ 'has-list': hasList }"
      >
        <BaseInput
          ref="input"
          v-model="result.name"
          class="input"
          placeholder="Enter your business name"
          @input="search"
        />
        <SpinningLoader v-if="isLoading" />
        <span v-else-if="isSelected" class="edit" @click="edit">Edit</span>
      </div>
    </form>
    <AddressList
      v-if="hasList"
      v-model="searchVal"
      class="list"
      :addresses="filteredCompanies"
      :item-component="CompanyListItem"
      :has-search="false"
      :has-manual="false"
      :is-loading="isLoading"
      @submit="select"
      @manual:show="notCompany"
    />
    <Button
      v-if="isSelected && !showDrawer"
      class="next-btn button--inline"
      :class-prop="buttonBg"
      :is-squared-button="true"
      :button-rounded-lg="buttonRoundedLg"
      :right-arrow-color="rightArrowColor"
      :right-arrow="true"
      :hover-state="true"
      @click="next"      
    />
    <div v-if="showDimmer" class="overlay">
      <div class="card">
        <div>
          <SpinningLoader class="spinner" color="white" />
          <img class="ch-logo" src="@/assets/images/companieshouse.png">
        </div>
        <div>
          <p>Loading data from</p>
          <strong>Companies House</strong>
        </div>
      </div>
    </div>

    <div v-if="showDrawer && !showDimmer" class="drawer-overlay" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { lowerCase, startCase } from 'lodash'
import { LIMITED_COMPANY } from '@/helpers/questions/options/typeQuestionData'
import SpinningLoader from '@mic/frontend/icons/SpinningLoader'
import AddressList from '@mic/frontend/inputs/AddressList'
import BaseInput from '@mic/frontend/inputs/BaseInput'
import Fuse from 'fuse.js'
import CompaniesHouseService from '../../services/CompaniesHouseService'
import sicCodes from '../../assets/data/sic'
import CompanyListItem from '../CompanyListItem'
import Button from '@/components/shared/Button'
import instrument from '../../helpers/instrument'
const debug = require('debug')('fh:components:Search')

const companiesHouseOfficerNameRegex = /^([A-Z-\s]+), ([A-Za-z-\s]+)$/

export default {
  name: 'SearchCompany',
  components: { SpinningLoader, AddressList, BaseInput, Button },

  props: {
    value: {
      type: Object,
      required: true
    },
    buttonRoundedLg: {
      type: Boolean,
      default: false
    },
    rightArrowColor: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      fuse: undefined,
      searchVal: '',
      result: { address: {}, officer: {}, name: '', isManual: false },
      companies: [],
      CompanyListItem,
      service: new CompaniesHouseService(),
      isSelected: false,
      isRequesting: false,
      showDrawer: false
    }
  },

  computed: {
    ...mapState({
      isWorking: state => state.Questions.isWorking
    }),
    ...mapGetters(['buttonBg']),
    searchListTitleExtra () {
      const businessType = this.$store.getters.getAnswerById('qid_type')?.result
      return businessType === LIMITED_COMPANY ? 'My business is not listed' : 'Sole trader or partnership'
    },
    filteredCompanies () {
      const results = this.fuse
        ? this.fuse.search(this.result.name)
        : this.companies

      const soleTraderItem = {
        title: `${this.result.name.toUpperCase()}`,
        titleExtra: this.searchListTitleExtra
      }

      return [soleTraderItem, ...results]
    },

    hasList () {
      return !this.isSelected && this.result.name && !this.isLoading
    },

    showDimmer () {
      return this.$store.getters.showDimmer
    },

    isLoading () {
      return this.isRequesting
    }
  },

  watch: {
    value (value) {
      this.result = {
        officer: value.result.officer || {},
        address: value.result.address || {},
        name: value.result.name,
        number: value.result.number
      }
    }
  },

  mounted () {
    this.focus()
    this.initFuse()
  },

  methods: {
    ...mapMutations(['SET_IS_WORKING']),
    initFuse () {
      this.fuse = new Fuse(this.companies, {
        keys: ['title'],
        shouldSort: true
      })
    },

    notCompany () {
      this.isSelected = true
      this.result.isManual = true
    },

    focus () {
      const { input } = this.$refs
      if (input && input.$el) {
        input.$el.focus()
      }
    },

    search (value, event) {
      if (event) {
        event.preventDefault()
      }

      if (!value && !this.result.name) {
        return
      }

      this.isRequesting = true
      this.service.debouncedSearch(this.result.name || value, response => {
        this.companies = response.data.items.filter(item => item.company_type !== 'oversea-company')
        this.initFuse()
        this.isRequesting = false
      })
    },

    edit () {
      this.isSelected = false
      this.showDrawer = false
      this.result.isManual = false
      this.companies = []
      this.focus()
      this.search()
    },

    select (companySelected) {
      const { company_number: companyNumber } = companySelected
      if (!companyNumber) {
        debug('select sole trader')
        instrument.selectSoleTraderOrPartnership()
        this.result.isManual = true
        this.result.name = companySelected.title
        this.isSelected = true
      } else {
        debug('select companies house company')
        instrument.selectCompaniesHouseCompany()
        const company = this.companies.find(
          company => company.company_number === companyNumber
        )

        this.result.name = company.title
        this.result.number = company.company_number
        this.isSelected = true
      }
    },

    next () {
      if (this.result.isManual) {
        return this.manual()
      }

      this.SET_IS_WORKING(true)
      this.automatically()
    },

    submit () {
      this.$emit('input', { result: this.result })
    },

    manual () {
      this.result.isManual = true
      this.submit()
      this.$emit('autoprogress')
    },

    automatically () {
      this.service.find(this.result.number).then(({ data }) => {
        this.result = { address: {} }
        this.result.name = data.company_name
        this.result.type = data.type
        this.result.number = data.company_number
        this.result.createdAt = new Date(data.date_of_creation)
        if (data.sic_codes) {
          if (sicCodes[data.sic_codes[0]]) {
            this.result.trade = sicCodes[data.sic_codes[0]].value
          } else {
            this.result.trade = 'Unknown'
          }
        }

        const address = data.registered_office_address
        this.result.address = address
        this.result.address.text = [
          address.address_line_1,
          address.address_line_2,
          address.locality,
          address.country,
          address.postal_code
        ]
          .filter(Boolean)
          .join(', ')

        this.result.officerOptions = data.officers.map(officer => {
          officer.name = startCase(lowerCase(officer.name.replace(companiesHouseOfficerNameRegex, '$2 $1')))
          return officer
        })
        this.result.officer = {}
        this.result.isManual = false

        this.$store.commit('SET_IS_WORKING', false)
        this.submit()
        this.$emit('autoprogress')
      }).catch(() => {
        this.SET_IS_WORKING(false)
      })
    }
  }
}
</script>

<style lang="scss">
  .search-company {
    .postcode-lookup {
      &__addresses {
        box-shadow: unset;
        max-width: 640px;
      }
      &__address-item {
        border-bottom: unset;
      }
    }
  }

  .question-content-height {
    .search-company {
      .next-btn {
        margin-top: 30vh;
      }
    }
  }

</style>

<style lang="scss" scoped>

.search-company {
  @media (min-width: $screen-md) {
    min-width: 640px;
  }
}
.card {
  font-size: 20px;
  padding: $spacing-20;
  margin: 0 auto;
  width: 338px;

  p {
    font-weight: 300;
    margin-bottom: 0;
  }

  img {
    width: 150px;
  }
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 14px;
  max-width: 640px;

  &.has-list {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input {
    font-size: 14px;
    color: #000;

    @media (min-width: $screen-md) {
      font-size: 16px;
    }
  }

  .edit {
    text-decoration: underline;
    cursor: pointer;
    font-size: 18px;
    padding-right: 5px;
    line-height: 80px - (12px * 2); // height - padding
  }

}

.confirmation-drawer {
  text-align: left;

  .title {
    font-size: 32px;
    line-height: 32px;
  }

  .subtitle {
    font-size: 20px;
  }

  .buttons {
    flex-shrink: 0;
    align-self: flex-end;

    > button {
      display: inline-block;

      &:last-of-type {
        margin-left: $spacing-28;
      }
    }

    .btn-enter-manually {
      padding: 1px 10px;
    }
  }
}

.ch-logo {
  img {
    width: 140px;
  }

  small {
    font-size: 12px;
  }
}

.overlay {
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;

  .ch-logo {
    width: 72px;
    position: absolute;
    top: 59px;
    left: 50%;
    margin-left: -37px;
  }

  .spinner {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    margin-bottom: $spacing-20;
    position: relative;

    ::v-deep .mic-loader__spinner {
      width: 106px;
      height: 106px;
    }
  }
}

.drawer-overlay {
  position: fixed;
  top: 75px;
  left: 0;
  right: 0;
  bottom: $confirmation-drawer-height-desktop;
  z-index: 300;
  transition: 0.5s all;
  background-color: rgba(#fff, 0.8);
  pointer-events: auto;

  @media (max-width: $screen-md) {
    top: 75px;
  }
}

@media (max-width: $screen-md) {
  .confirmation-drawer {
    .title {
      font-size: 18px;
      line-height: 18px;
      margin: 0;
    }

    .subtitle {
      font-size: 13px;
      line-height: 10px;
    }

    .buttons {
      > button {
        display: block;
        margin: 0;
        width: 100%;

        &:last-of-type {
          margin-left: 0;
          margin-top: $spacing-12;
        }
      }
    }

    .ch-logo {
      small {
        font-size: 8px;
      }

      img {
        width: 70px;
      }
    }
  }
}

.drawer-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.list {
  margin: 0 auto;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  @media (min-width: $screen-md) {
    max-width: 640px;
  }

  @media (max-width: $mobile-breakpoint-max) {
    height: calc(100vh - 330px);
  }
}

::v-deep {
  .postcode-lookup__address-list {
    @media (max-width: $mobile-breakpoint-max) {
      max-height: none;
    }
  }
}

</style>
