import { LOW, BORDERLINE, WELCOME_TEAM } from '@/helpers/constants/turnover-freshold-amounts'

export default function (result, pageName) {
  let routerName
  let amount = Number(result)

  if (amount < LOW) {
    routerName = `lt-${pageName}`
  }

  if (amount >= LOW && amount < BORDERLINE) {
    routerName = `bl-${pageName}`
  }

  if (amount >= BORDERLINE && amount < WELCOME_TEAM) {
    routerName = `wt-${pageName}`
  }

  if (amount >= WELCOME_TEAM) {
    routerName = `mj-${pageName}`
  }

  return routerName
}
