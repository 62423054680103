<template>
  <div 
    class="confirmation-drawer" 
    :class="[ isMinimizedClass, checkIfTurnoverQ ]"
  >
    <div class="container confirmation-drawer__progress-content">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="confirmation-drawer__content-wrap" v-html="message" />

      <div class="confirmation-drawer__button-wrap">
        <Button 
          :is-squared-button="true"
          :class-prop="buttonBg"
          :right-arrow="true"
          :right-arrow-color="rightArrowColor"
          :button-rounded-lg="buttonRoundedLg"
          class="next-btn" 
          :hover-state="true"
          @click="next" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '@/components/shared/Button'
import { TURNOVER } from '@/helpers/constants/question-slugs'

export default {
  name: 'ConfirmationDrawer',
  components: {
    Button
  },
  props: {
    message: {
      type: String,
      required: false,
      default: ''
    },
    isMinimized: {
      type: Boolean,
      required: false,
      default: false
    },
    currentQSlug: {
      type: String,
      required: false,
      default: ''
    },
    buttonBg: {
      type: String,
      default: ''
    },
    rightArrowColor: {
      type: String,
      default: ''
    },
    buttonRoundedLg: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      hideable: false,
      hideTimer: undefined
    }
  },

  computed: {
    ...mapGetters(['getPartner']),
    checkIfTurnoverQ () {
      if (this.currentQSlug !== TURNOVER) return ''
      return 'confirmation-drawer--slow-transition'
    },
    isMinimizedClass () {
      if (!this.isMinimized) return ''
      return 'minimized'
    }
  },

  watch: {
    isMinimized (newVal) {
      if (!this.isMinimized) {
        this.setHideTimer()
      }
    }
  },

  mounted () {
    this.setHideTimer()
  },

  methods: {
    setHideTimer () {
      // Used to prevent double-clicks hiding the drawer immediately
      this.hideable = false
      clearTimeout(this.hideTimer)

      this.hideTimer = setTimeout(() => {
        this.hideable = true
      }, 500)
    },
    next () {
      this.$emit('next')
    }
  }
}
</script>
<style lang="scss">
  .confirmation-drawer {
    .next-btn.button--squared .button {
      width: 128px;
      @media (max-width: $xs-width) {
        width: 126px;
        height: 42px;
      }

      .button-text {
        font-size: 16px;
      }
    }
  }
</style>
<style scoped lang="scss">
.confirmation-drawer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  min-height: 163px;
  height: 0;
  z-index: 200;
  display: flex;
  flex-direction: column;
  transition: .5s transform;
  background-color: $white;
  box-shadow: $bionic-shadow-drawer;
  border-radius: 16px 16px 0 0;

  &--slow-transition {
      transition: 2.7s transform;
  }

  @media (min-width: $xs-width) {
      min-height: 180px;
  }

  &.minimized {
    transform: translateY(100%);
  }

  &__progress-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    flex-direction: row;
    height: 100%;
    padding: 20px;
    max-width: 700px;

    @media (max-width: $screen-md) {
      flex-direction: column;
    }
  }

  &__content-wrap {
    flex-grow: 1;
    text-align: left;
    padding: $spacing-20;
    transition: all 0.75s 0.2s;
    font-size: 18px;
    color: #000;
    max-width: 560px;
    margin-bottom: 0;
    line-height: 22px;

    @media (max-width: $screen-md) {
      font-size: 14px;
      padding: 0;
      margin: 0;
      line-height: 18px;
    }

    .minimized & {
      opacity: 0;
      transition: all 0.5s;
      transform: translateY(50px);
    }

  }
    &__button-wrap {
      transition: all 0.75s 0.4s;
      @media(max-width: $md-width) {
        margin-left: auto;
      }

      .next-btn {
        min-width: 177px;

        @media (max-width: $xs-width) {
          min-width: 0;
          width: 128px;
        }
        
      }

    .minimized & {
    transition: all 0.5s;
      opacity: 0;
      transform: translateY(50px);
    }
  }

}

</style>
