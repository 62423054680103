<template>
  <div class="choice-boxes" :class="boxCount">
    <div
      v-for="(choice, i) in choices"
      :key="i"
      :class="[i === activeItem ? 'choice-boxes__box--active' : '', `choice-boxes__box--${boxColor}`]"
      class="
        justify-content-center
        align-items-center
        choice-boxes__box
      "
      @click="selectItem(i)"
    >
      <span class="choice-boxes__box__text"> {{ choice.choice }} </span>
      <span
        v-if="choice.tooltip"
        v-tooltip="{
          content: choice.tooltip,
          classes: 'choice-box-tooltip tooltip-arrow-show',
          placement: 'bottom',
          show: false,
        }"
        class="desktop-only-inline title-tooltip__outer"
      >
        <img src="@/assets/icons/more-info.png" alt="?">
      </span>
    </div>
  </div>
</template>

<script>
import { VTooltip } from 'v-tooltip'
export default {
  name: 'ChoiceBox',
  directives: {
    tooltip: VTooltip
  },
  props: {
    choices: {
      type: Array,
      required: true
    },
    customChoice: {
      type: Boolean,
      default: false
    },
    boxColor: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      activeItem: null
    }
  },
  computed: {
    boxCount () {
      if (this.choices.length >= 4) return 'choice-boxes--grid'
      return ''
    }
  },
  methods: {
    selectItem (i) {
      // active class added on click of v-for item
      this.$emit('trackingClick', this.choices[i])
      this.activeItem = i
      if (this.customChoice) {
        this.$emit('clicked', this.choices[i].customChoice)
      } else {
        this.$emit('clicked', this.choices[i].choice)
      }
    }
  }
}
</script>
<style lang="scss">
.choice-boxes {
  .title-tooltip__outer {
    img {
      width: 16px;
    }
  }
}

.tooltip-arrow-show {
  .tooltip-arrow {
    display: block !important;
  }
}
.choice-box-tooltip {
  width: 395px;

  img {
    width: 16px;
  }

  .tooltip-inner {
    font-size: 15px;
    padding: 20px;
  }
}

.isFeedbackQuestion {
  .choice-boxes {
    height: 100vh;
  }
}
</style>

<style lang="scss" scoped>
.choice-boxes {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $screen-md) {
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
    max-width: unset;
    width: unset;
  }

  &--grid {
    @media (min-width: $screen-md) {
      width: 712px;
      flex-direction: row;
    }
  }

  &--grid &__box {
    @media (min-width: $screen-md) {
      margin: 7px;
    }
  }

  &__box {
    cursor: pointer;
    color: $black;
    margin-bottom: 8px;
    position: relative;
    border-radius: 6px;
    height: 52px;
    width: 100%;
    background-color: $white;
    padding: 12px 16px;
    display: flex;
    border: 1px solid #deddde;

    @media (min-width: $tablet-min) {
      width: 340px;
      margin-bottom: 14px;
      height: 56px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &__text {
      flex-grow: 1;
      font-size: 14px;
      line-height: 19.2px;

      @media (min-width: $tablet-min) {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 19.2px;
      }
    }

    &:hover {
      @media (min-width: $screen-md) {
        border: 1px solid $blue-1;
        position: relative;
        top: -2px;
      }
    }

    &--active {
      border: 1px solid $blue-1;
    }

    &--purple {
      &:hover {
        border: 1px solid $purple-1;
      }
    }
  }
}
.choice-boxes__box--purple.choice-boxes__box--active {
    border: 1px solid $purple-1;
}
</style>
