import axios from 'axios'
import { debounce } from 'lodash'

export class CompaniesHouseServiceError extends Error {}
export class MissingBaseUrlError extends CompaniesHouseServiceError {
  constructor () {
    super('a baseUrl is required')
  }
}
export class MissingSearchQueryError extends CompaniesHouseServiceError {
  constructor () {
    super('a search query is required')
  }
}
export class MissingCompanyNumberError extends CompaniesHouseServiceError {
  constructor () {
    super('a company number is required')
  }
}

export default class CompaniesHouseService {
  constructor (
    baseUrl = process.env.VUE_APP_COMPANIES_HOUSE_BASE_URI
  ) {
    this.baseUrl = baseUrl

    if (!this.baseUrl) {
      throw new MissingBaseUrlError()
    }

    this.client = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    this.debouncedSearch = debounce((query, callback) => this.search(query).then(callback), 500)
  }

  search (query) {
    if (!query) throw new MissingSearchQueryError()

    return this.client.get(`/?type=search&q=${query}`)
  }

  find (number) {
    if (!number) throw new MissingCompanyNumberError()

    return this.client.get(`/?type=fetch&q=${number}`)
  }
}
