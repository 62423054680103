import track from './track'

const instrument = {
  selectSoleTraderOrPartnership: () => {
    const data = {
      class: 'soletrader-partnership-option-selected',
      id: 'search-soletrader-partnership-selected',
      name: 'soletrader-partnership-selected',
      text: 'business',
      type: 'submit'
    }

    track.buttonClick(data)
  },

  selectCompaniesHouseCompany: () => {
    const data = {
      class: 'business-name-ch-option-selected',
      id: 'search-business-name-selected',
      name: 'business-name-selected',
      text: 'business',
      type: 'submit'
    }

    track.buttonClick(data)
  },

  iwocaAffiliateClick: () => {
    const data = {
      class: 'affiliate-page-button',
      id: 'affiliate-confirmation-button',
      name: 'iwoca-affiliate-confirmation-button',
      text: 'continue',
      type: 'submit'
    }
    track.buttonClick(data)
  },

  choiceBoxClick: (text, TrackingClass, id, name) => {
    const data = {
      class: TrackingClass,
      id,
      name,
      text,
      type: 'submit'
    }
    track.buttonClick(data)
  },

  turnoverResults: (result, turnoverAmount) => {
    const data = {
      class: 'question-turnover-next',
      id: 'question-turnover-next',
      name: 'finance-question-turnover',
      text: 'Next',
      type: 'submit',
      amount: Number(turnoverAmount),
      range: result.rangeValues,
      segment: result.segment
    }
    track.buttonClickTurnover(data)
  },

  submitForm: () => {
    track.submitCallNow()
  }
}

export default instrument
