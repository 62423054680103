import checkCorporate from '@/helpers/corporate/checkCorporate'
import { TURNOVER_MID_FRESHOLD } from '@/helpers/constants/freshold-amounts'
export const TURNOVER_CORPORATE_MESSAGE = `<span class="font-weight-bold">Perfect</span>, we'll connect you to our corporate team who specialise in getting the best rates for businesses your size.`
export const TURNOVER_MID_FRESHOLD_MESSAGE = `<span class="font-weight-bold">Perfect</span>, your lending manager will run a comprehensive search and talk through your options with you.`
export const TURNOVER_LOWER_FRESHOLD_MESSAGE = `<span class="font-weight-bold">Perfect</span>, sometimes the best business loans come from lenders that can create a custom solution for your business.`

export const turnoverFeedbackMessaging = (answer) => {
  if (answer) {
    // custom feedback based on user typing input
    if (checkCorporate(answer)) {
      return TURNOVER_CORPORATE_MESSAGE
    } else if (answer > TURNOVER_MID_FRESHOLD) {
      return TURNOVER_MID_FRESHOLD_MESSAGE
    } else {
      return TURNOVER_LOWER_FRESHOLD_MESSAGE
    }
  }
}
