<template>
  <div>
    <form @submit.prevent="next">
      <span v-if="!isValid && isDirty" class="error">
        {{ validationErrorMessage }}
      </span>
      <div class="input-container input-container--border">
        <BaseInput
          ref="input"
          v-model="displayResult"
          inputmode="numeric"
          :pattern="numericalInputPattern"
          type="text"
          class="d-inline input"
          :class="{ 'input-center': data.centerInput }"
          :placeholder="placeholder"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import BaseInput from '@mic/frontend/inputs/BaseInput'
import { numericalInputPattern } from '@/helpers/constants/regex-patterns'

export default {
  name: 'NumericInput',

  components: {
    BaseInput
  },

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    value: {
      type: Object,
      required: true
    },

    placeholder: {
      type: String,
      default: 'Enter number of years trading'
    }
  },

  data () {
    return {
      isDirty: false,
      isValid: false,
      result: this.value.result,
      format: new Intl.NumberFormat('en-GB').format,
      setIsDirtyDebounced: debounce(() => {
        this.isDirty = true
      }, 400),
      numericalInputPattern
    }
  },

  computed: {
    displayResult: {
      get () {
        return this.result ? this.format(this.result) : ''
      },
      set (result) {
        this.setIsDirtyDebounced()
        this.result = result.replace(/\D+/g, '')
        this.$nextTick(() => {
          this.validateInput()
        })
      }
    },

    min () {
      return this.data && this.data.min ? this.data.min : undefined
    },

    validationErrorMessage () {
      if (this.data && this.data.min) {
        return `Please enter at least ${this.format(this.data.min)}`
      } else {
        return `Please enter a valid number`
      }
    }
  },

  mounted () {
    this.focus()
  },

  methods: {
    validateInput () {
      if (this.data.min) {
        this.isValid = parseInt(this.result) >= this.data.min
      } else {
        this.isValid = !Number.isNaN(this.result)
      }

      this.submit()
    },

    focus () {
      const { input } = this.$refs
      if (input && input.$el) {
        input.$el.focus()
      }
    },

    next () {
      if (this.isValid) {
        this.$emit('autoprogress')
      }
    },

    submit () {
      this.$emit('input', {
        result: this.isValid ? this.result : undefined
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  text-align: left;
}

.list-group-item {
  cursor: pointer;

  &:hover {
    background: $bionic-grey-300;
  }
}

.input-container .currency {
  border-right: 1px solid rgba(3, 41, 66, 0.5);
  font-size: 40px;
  padding-right: $spacing-12;
  padding-left: $spacing-8;
  margin-right: $spacing-12;
  font-weight: 600;
}

.error {
  color: red;
  margin-bottom: 20px;
}

.input-container {
  width: 340px;
  display: flex;
  align-items: center;

  @media (max-width: 540px) {
    width: 100%;
  }
}

@media (max-width: $mobile-breakpoint-max) {
  .input-container .currency {
    font-size: 28px;
  }

  .input-container {
    line-height: 56px;
    font-size: 16px;
  }

  .error {
    font-size: 16px;
  }
}
</style>
