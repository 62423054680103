<template>
  <ChoiceBox 
    :choices="choices"
    :box-color="boxColor"
    @clicked="onClickChild"
    @trackingClick="trackingClicked"
  />
</template>

<script>
import ChoiceBox from '@/components/question/ChoiceBox'
import instrument from '@/helpers/instrument'
import { mapGetters } from 'vuex'

export default {
  name: 'ChoiceBoxes',
  components: {
    ChoiceBox
  },
  data () {
    return {
      choices: this.$attrs.data.choices
    }
  },
  computed: {
    ...mapGetters(['isMoneyTest']),
    boxColor () {
      return this.isMoneyTest ? 'purple' : ''
    }
  },
  methods: {
    trackingClicked (value) {
      instrument.choiceBoxClick(value.choice, value.tracking.class, value.tracking.id, value.tracking.name)
    },
    onClickChild (value) {
      this.$emit('input', {
        result: value
      })
    }
  }
}
</script>
