import { ASSETS_QUESTION, NERDWALLET_ASSETS } from '@/helpers/constants/journey-names'
import { ASSETFINANCE, BIONIC, COMPARE, FORBES, MONEY, MSM, NERDWALLET, USW } from '@/helpers/constants/partner-names'
import getSubdomain from '@/helpers/urls/getSubdomain'

export default function journeyTypeCheck (journeyNumber, domainArg) {
  let subdomain
  if (!domainArg) {
  // e.g nerdwallet.thinkbusinessloans.com
    subdomain = getSubdomain(window.location.hostname)
  } else {
    subdomain = domainArg
  }

  if (subdomain === COMPARE) {
    return COMPARE
  }
  if (subdomain === USW) {
    return USW
  } if (subdomain === NERDWALLET) {
    return NERDWALLET_ASSETS
  } if (subdomain === ASSETFINANCE) {
    return ASSETS_QUESTION
  } if (subdomain === MONEY) {
    return MONEY
  } if (subdomain === MSM) {
    return MSM
  }
  if (subdomain === FORBES) {
    return FORBES
  } 
  if (subdomain === BIONIC) {
    return BIONIC
  } else {
    return journeyNumber
  }
}
