<template>
  <div class="turnover-page">
    <GbpInput
      :value="value"
      placeholder="Enter amount"
      :add-border="true"
      :center-input="data.centerInput"
      @input="input"
    />
  </div>
</template>

<script>
import GbpInput from './GbpInput'

const debug = require('debug')('fh:components:Turnover')

export default {
  name: 'Turnover',

  components: { GbpInput },

  props: {
    value: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      showModal: false,
      thisYearsTurnover: { result: '' },
      format: new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format
    }
  },

  computed: {
    thisYearsTurnoverResult () {
      if (!this.thisYearsTurnover.result) return

      return this.format(this.thisYearsTurnover.result)
    },

    isButtonDisabled () {
      if (!this.thisYearsTurnover.result) {
        debug('isButtonDisabled', true)
        return true
      }

      const isDisabled = this.thisYearsTurnover.result === ''
      debug('isButtonDisabled', isDisabled)
      return isDisabled
    }
  },

  methods: {
    input (value) {
      this.$emit('input', value)
    },

    openModal () {
      this.showModal = true
    },

    closeModal () {
      this.showModal = false
    }
  }
}
</script>
<style lang="scss">
.turnover-page {
  .input-container {
    @media(max-width: 560px) {
      width: 100%;
    }
  }
}
</style>
<style lang="scss" scoped>
p {
  font-size: 21px;
  color: $main-title-color;
  margin-top: $spacing-32;
}

button {
  font-weight: 600;
  text-decoration: underline;
  padding: 0;
  margin: 0 0 0 $spacing-8;
  border: none;
  background: none;
  color: $main-title-color;
}

.overlay {
  position: absolute;
  background: rgba($black, 0.5);
  padding-top: 80px;
  min-height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;

  .card {
    max-width: 510px;
    width: 100%;
    padding: $spacing-40 $spacing-32 $spacing-28;
    margin: 0 auto;
    position: relative;
    border-radius: $border-radius-large;

    img {
      cursor: pointer;
      position: absolute;
      width: 20px;
      height: 20px;
      right: $spacing-20;
      top: $spacing-20;
    }

    h1 {
      margin-bottom: $spacing-12;
      font-weight: 700;
      font-size: 40px;
    }

    p {
      margin: 0 0 $spacing-28;
      font-size: 21px;
      color: $text-color;
    }

    button {
      margin-top: $spacing-28;
    text-decoration: none;
    }
  }
}
</style>
