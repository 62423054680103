<template>
  <div :class="getPartner.name">
    <NavbarJourney :telephone="getPhone" :friendly-telephone="getFriendlyPhone" :telephone-color="dynamicColor" />
    <div 
      :class="{'isFeedbackQuestion': question.feedbackMultiChoiceMessage && hasAnswer }"
      class="question-page"
    >
      <transition name="slide-fade" mode="out-in">
        <div :key="title" class="question-block">
          <div class="question-content" :class="{'question-content-height': hasInlineNextButton}">
            <QuestionTitle 
              v-if="isTitleEnabled"
              class="animation-cascade__item"
              :title="title" 
              :subtitle="subtitle"
              :tooltip="tooltipTitle"
              :tooltip-mobile-only="tooltipMobileOnly"
              :is-sm-custom-title="titleSmCustom"
              :title-color-class="titleColor"
            />
            <component
              :is="componentOptions[question.type]"
              :key="componentKey"
              v-model="answer"
              :button-rounded-lg="isMoneyTest"
              :right-arrow-color="dynamicColor"
              class="animation-cascade__item"
              :data="question"
              @autoprogress="next"
            />
          </div>

          <div v-if="question.inlineNextButton" class="next-btn-wrap text-right animation-cascade__item">
            <Button 
              :class-prop="buttonBg"
              class="next-btn button--inline"
              :disabled="!hasAnswer" 
              :is-squared-button="true"
              :button-rounded-lg="isMoneyTest"
              :right-arrow-color="dynamicColor"
              :right-arrow="true"
              :hover-state="true"
              @click="next" 
            />
          </div>
        </div>
      </transition>
      <TrustPilot
        class="question-page__trust-pilot"
      />
      <ConfirmationDrawer
        v-if="checkCustomOrNormalMessage"
        :is-minimized="!hasAnswer"
        :message="questionMessage"
        :current-q-slug="question.slug"
        :button-bg="buttonBg"
        :right-arrow-color="dynamicColor"
        :button-rounded-lg="isMoneyTest"
        @next="next"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { turnoverFeedbackMessaging } from '@/helpers/questions/turnoverFeedback'
import { TURNOVER } from '@/helpers/constants/question-slugs'
import multipleChoiceFeedback from '@/helpers/questions/multipleChoiceFeedback'
import TradeInfo from '@mic/frontend/mixins/TradeInfo'
import Routable from '@mic/frontend/mixins/Routable'
import SaveQuery from '../mixins/SaveQuery'
import ConfirmationDrawer from '@/components/progress/ConfirmationDrawer'
import QuestionTitle from '@/components/question/QuestionTitle'
import SearchCompany from '../components/question/SearchCompany'
import GbpInput from '../components/question/GbpInput'
import NumericInput from '../components/question/NumericInput'
import Turnover from '../components/question/Turnover'
import AssetsQuestion from '../components/question/AssetsQuestion'
import Button from '@/components/shared/Button'
import NavbarJourney from '@/components/shared/NavbarJourney'
import ChoiceBoxes from '@/components/question/ChoiceBoxes'
import TextInput from '@/components/question/TextInput'
import getNextQuestion from '@/helpers/questions/getNextQuestion'
import TrustPilot from '@/components/results/TrustPilot'
import findRange from '@/helpers/ranges/find-range'
import instrument from '@/helpers/instrument'
import turnOverRoutes from '@/helpers/turnover/turnover-routes'
import { USW_THINK_TEST } from '@/helpers/constants/journey-names'
import { USW, COMPARE } from '@/helpers/constants/partner-names'
import journeyTypeCheck from '@/helpers/journeys/journeyTypeCheck'

const debug = require('debug')('fh:views:QuestionPage')

export default {
  name: 'QuestionPage',

  components: {
    QuestionTitle,
    ConfirmationDrawer,
    Button,
    NavbarJourney,
    TrustPilot
  },

  mixins: [TradeInfo, Routable, SaveQuery],

  data () {
    return {
      showLoadingScreen: false,
      answer: {},
      componentOptions: {
        search: SearchCompany,
        gbpinput: GbpInput,
        numericinput: NumericInput,
        turnover: Turnover,
        assets: AssetsQuestion,
        purpose: ChoiceBoxes,
        when: ChoiceBoxes,
        bussiness: ChoiceBoxes,
        text: TextInput
      }
    }
  },

  computed: {
    ...mapState({
      isWorking: state => {
        const result = state.Questions.isWorking
        return result
      },
      testGroup: state => state.AbTest.testGroup
    }),
    ...mapGetters(['getQuestions', 'getPartner', 'getCurrentAnswer', 'getPhone', 'getFriendlyPhone', 'buttonBg', 'getJourney', 'dynamicColor', 'isMoneyTest', 'titleColor']),
    isTitleEnabled () {
      return this.question.title !== undefined
    },
    title () {
      if (typeof this.question.title === 'string') {
        return this.question.title
      } else {
        return this.question.title[this.$store.getters.isManual ? 'manual' : 'auto']
      }
    },
    titleSmCustom () {
      if (this.question.titleSmCustom) return true
      return false
    },
    tooltipTitle () {
      if (!this.question.tooltipTitle) return null
      return this.question.tooltipTitle
    },
    tooltipMobileOnly () {
      if (!this.question.tooltipMobileOnly) return null
      return this.question.tooltipMobileOnly
    },
    subtitle () {
      if (typeof this.question.subtitle === 'string') {
        return this.question.subtitle
      } else {
        return this.question.subtitle[this.$store.getters.isManual ? 'manual' : 'auto']
      }
    },
    questions () {
      return this.getQuestions
    },
    question () {
      return this.questions.find(q => q.slug === this.$route.params.slug)
    },
    hasInlineNextButton () {
      return this.question.inlineNextButton || this.question.slug === 'search'
    },
    nextQuestion () {
      debug('nextQuestion')
      debug('slug', this.question.slug)
      return getNextQuestion(this.question.slug, this.getCurrentAnswer, this.getJourney)
    },
    hasAnswer () {
      return this.answer && this.answer.result && this.answer.valid !== false
    },
    isLastQuestion () {
      const nextQuestion = getNextQuestion(this.question.slug, this.getCurrentAnswer, this.getJourney)

      return nextQuestion === null
    },
    componentKey () {
      return this.$route.path
    },
    getTradeInfo () {
      return this.question.type === 'tradelist'
        ? this.getTradeInfoByTrade(this.answer.result)
        : ''
    },
    checkCustomOrNormalMessage () {
      if (this.question.message || this.question.feedbackMultiChoiceMessage || this.question.customTypedFeedbackMessage) {
        return true
      } else {
        return false
      }
    },
    questionMessage () {
      // checking for turnover question
      if (this.question.slug === TURNOVER) {
        // check amount and display custom messsage
        return turnoverFeedbackMessaging(this.answer.result)
      }
      // multiple choice feedback messaging
      return multipleChoiceFeedback(this.question, this.answer)
    },
    turnOverAmount () {
      return this.$store.getters.getAnswerById('qid_turnover')
    }
  },

  watch: {
    $route (to, from) {
      window.scrollTo(0, 0)
      this.unsetAnswer()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.addClassName('.question-content', 'animation-questions-on-load-cascade')
    this.unsetAnswer()
  },
  created () {
    // which journey name to use - can be used for split tests
    const journeyType = journeyTypeCheck()
    if (journeyType === COMPARE && this.testGroup) {
      this.setJourney(USW_THINK_TEST)
    } else if (journeyType === USW && this.testGroup) {
      this.setJourney(USW_THINK_TEST)
    } else {
      this.setJourney(journeyType)
    }

    this.mixinSaveQuery()
    this.fetchStoreAnswer()
    if (this.$route.query.postcode) {
      this.answer.postcode = this.$route.query.postcode
      this.$store.commit('UPDATE_POSTCODE_INPUT', this.answer.postcode)
      this.$store.dispatch('actionLoadAddresses', this.answer.postcode)
    }
  },

  methods: {
    ...mapMutations(['SET_ANSWER', 'SET_CURRENT_QUESTION']),
    ...mapActions(['actionSetAnswer', 'setJourney']),
    next () {
      this.actionSetAnswer({
        questionId: this.question.questionId,
        answer: this.answer
      })

      if (this.question.slug === TURNOVER) {
        instrument.turnoverResults(findRange(this.answer.result), this.answer.result)
      }
      
      if (this.isLastQuestion) {
        // turnover based url outcome
        const routerName = turnOverRoutes(this.turnOverAmount.result, 'join-call')
        this.$router.push({ name: routerName })
      } else {
        this.$router.push(`/question/${this.nextQuestion.slug}`)
      }
    },
    unsetAnswer () {
      this.SET_ANSWER({
        questionId: this.question.questionId,
        answer: {}
      })

      this.fetchStoreAnswer()
    },
    fetchStoreAnswer () {
      this.SET_CURRENT_QUESTION(this.question.questionId)
      this.$set(this, 'answer', this.getCurrentAnswer)
    },
    addClassName (elm, className) {
      document.querySelector(elm).classList.add(className)
    },
    removeClassName (elm, className) {
      document.querySelector(elm).classList.remove(className)
    }
  }
}
</script>

<style lang="scss">
  .animation-questions-on-load-cascade {
    .animation-cascade {
      &__item:nth-child(1) {
        .title {
          @include fadeInUpAnimation;
          animation-delay: $casecadeDelay;
        }
        .subtitle {
             @include fadeInUpAnimation;
            animation-delay: $casecadeDelay + 50;
        }
      }
      &__item {
        .choice-boxes__box:nth-child(1) {
            @include fadeInUpAnimation;
              animation-delay: $casecadeDelay + 100;
        }
         .choice-boxes__box:nth-child(2) {
            @include fadeInUpAnimation;
               animation-delay: $casecadeDelay + 150;
        }
        .choice-boxes__box:nth-child(3) {
            @include fadeInUpAnimation;
              animation-delay: $casecadeDelay + 200;
        }
      
      }
    }
  }

  @include fadeInUpShortKeyFrames;

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>

<style lang="scss" scoped>
.question-page {
  overflow: visible;
  background: transparent;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  @media (max-width: $screen-md) {

    margin-bottom: 150px;
  }

  .question-content {
    display: flex;
    flex-direction: column;
    @media (max-width: $screen-md) {
      flex-grow: 1;
    }

    &-height {
      @media (max-width: $screen-md) {
        height: 56vh;
      }
    }   
  }

  .question-block {
    padding-left: 24px;
    padding-right: 24px;
    background-color: #f7f8f9;
    z-index: 99;
    
    @media (min-width: $screen-md) {
      margin-left: auto;
      margin-right: auto;
    }

  }

  .next-btn-wrap {
    margin-top: $spacing-28;
    display: flex;

    @media (max-width: $screen-md) {
      margin: $spacing-28 0 $spacing-28 0;
    }
  }

  &__trust-pilot {
      margin-top: 0;
      position: fixed;
      bottom: -70px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
  }
}

.isFeedbackQuestion {
  margin-bottom: 0;
  .question-content {
      @media (min-width: $screen-md) {
       height: 100vh;
   }
  }

}

.count {
  font-size: 16px;
  font-weight: bold;
}
</style>
