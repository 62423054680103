export default {
  '10110': { value: 'Processing and preserving of meat' },
  '10120': { value: 'Processing and preserving of poultry meat' },
  '10130': { value: 'Production of meat and poultry meat products' },
  '10200': {
    value: 'Processing and preserving of fish, crustaceans and molluscs'
  },
  '10310': { value: 'Processing and preserving of potatoes' },
  '10320': { value: 'Manufacture of fruit and vegetable juice' },
  '10390': { value: 'Other processing and preserving of fruit and vegetables' },
  '10410': { value: 'Manufacture of oils and fats' },
  '10420': { value: 'Manufacture of margarine and similar edible fats' },
  '10511': { value: 'Liquid milk and cream production' },
  '10512': { value: 'Butter and cheese production' },
  '10519': { value: 'Manufacture of other milk products' },
  '10520': { value: 'Manufacture of ice cream' },
  '10611': { value: 'Grain milling' },
  '10612': { value: 'Manufacture of breakfast cereals and cereals-based food' },
  '10620': { value: 'Manufacture of starches and starch products' },
  '10710': {
    value: 'Manufacture of bread; manufacture of fresh pastry goods and cakes'
  },
  '10720': {
    value:
      'Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes'
  },
  '10730': {
    value:
      'Manufacture of macaroni, noodles, couscous and similar farinaceous products'
  },
  '10810': { value: 'Manufacture of sugar' },
  '10821': { value: 'Manufacture of cocoa and chocolate confectionery' },
  '10822': { value: 'Manufacture of sugar confectionery' },
  '10831': { value: 'Tea processing' },
  '10832': { value: 'Production of coffee and coffee substitutes' },
  '10840': { value: 'Manufacture of condiments and seasonings' },
  '10850': { value: 'Manufacture of prepared meals and dishes' },
  '10860': {
    value: 'Manufacture of homogenized food preparations and dietetic food'
  },
  '10890': { value: 'Manufacture of other food products n.e.c.' },
  '10910': { value: 'Manufacture of prepared feeds for farm animals' },
  '10920': { value: 'Manufacture of prepared pet foods' },
  '11010': { value: 'Distilling, rectifying and blending of spirits' },
  '11020': { value: 'Manufacture of wine from grape' },
  '11030': { value: 'Manufacture of cider and other fruit wines' },
  '11040': { value: 'Manufacture of other non-distilled fermented beverages' },
  '11050': { value: 'Manufacture of beer' },
  '11060': { value: 'Manufacture of malt' },
  '11070': {
    value:
      'Manufacture of soft drinks; production of mineral waters and other bottled waters'
  },
  '12000': { value: 'Manufacture of tobacco products' },
  '13100': { value: 'Preparation and spinning of textile fibres' },
  '13200': { value: 'Weaving of textiles' },
  '13300': { value: 'Finishing of textiles' },
  '13910': { value: 'Manufacture of knitted and crocheted fabrics' },
  '13921': { value: 'Manufacture of soft furnishings' },
  '13922': { value: 'manufacture of canvas goods, sacks, etc.' },
  '13923': { value: 'manufacture of household textiles' },
  '13931': { value: 'Manufacture of woven or tufted carpets and rugs' },
  '13939': { value: 'Manufacture of other carpets and rugs' },
  '13940': { value: 'Manufacture of cordage, rope, twine and netting' },
  '13950': {
    value:
      'Manufacture of non-wovens and articles made from non-wovens, except apparel'
  },
  '13960': { value: 'Manufacture of other technical and industrial textiles' },
  '13990': { value: 'Manufacture of other textiles n.e.c.' },
  '14110': { value: 'Manufacture of leather clothes' },
  '14120': { value: 'Manufacture of workwear' },
  '14131': { value: "Manufacture of other men's outerwear" },
  '14132': { value: "Manufacture of other women's outerwear" },
  '14141': { value: "Manufacture of men's underwear" },
  '14142': { value: "Manufacture of women's underwear" },
  '14190': {
    value: 'Manufacture of other wearing apparel and accessories n.e.c.'
  },
  '14200': { value: 'Manufacture of articles of fur' },
  '14310': { value: 'Manufacture of knitted and crocheted hosiery' },
  '14390': { value: 'Manufacture of other knitted and crocheted apparel' },
  '15110': {
    value: 'Tanning and dressing of leather; dressing and dyeing of fur'
  },
  '15120': {
    value: 'Manufacture of luggage, handbags and the like, saddlery and harness'
  },
  '15200': { value: 'Manufacture of footwear' },
  '16100': { value: 'Sawmilling and planing of wood' },
  '16210': { value: 'Manufacture of veneer sheets and wood-based panels' },
  '16220': { value: 'Manufacture of assembled parquet floors' },
  '16230': { value: "Manufacture of other builders' carpentry and joinery" },
  '16240': { value: 'Manufacture of wooden containers' },
  '16290': {
    value:
      'Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials'
  },
  '17110': { value: 'Manufacture of pulp' },
  '17120': { value: 'Manufacture of paper and paperboard' },
  '17211': {
    value: 'Manufacture of corrugated paper and paperboard, sacks and bags'
  },
  '17219': { value: 'Manufacture of other paper and paperboard containers' },
  '17220': {
    value:
      'Manufacture of household and sanitary goods and of toilet requisites'
  },
  '17230': { value: 'Manufacture of paper stationery' },
  '17240': { value: 'Manufacture of wallpaper' },
  '17290': {
    value: 'Manufacture of other articles of paper and paperboard n.e.c.'
  },
  '18110': { value: 'Printing of newspapers' },
  '18121': { value: 'Manufacture of printed labels' },
  '18129': { value: 'Printing n.e.c.' },
  '18130': { value: 'Pre-press and pre-media services' },
  '18140': { value: 'Binding and related services' },
  '18201': { value: 'Reproduction of sound recording' },
  '18202': { value: 'Reproduction of video recording' },
  '18203': { value: 'Reproduction of computer media' },
  '19100': { value: 'Manufacture of coke oven products' },
  '19201': { value: 'Mineral oil refining' },
  '19209': {
    value:
      'Other treatment of petroleum products (excluding petrochemicals manufacture)'
  },
  '20110': { value: 'Manufacture of industrial gases' },
  '20120': { value: 'Manufacture of dyes and pigments' },
  '20130': { value: 'Manufacture of other inorganic basic chemicals' },
  '20140': { value: 'Manufacture of other organic basic chemicals' },
  '20150': { value: 'Manufacture of fertilizers and nitrogen compounds' },
  '20160': { value: 'Manufacture of plastics in primary forms' },
  '20170': { value: 'Manufacture of synthetic rubber in primary forms' },
  '20200': {
    value: 'Manufacture of pesticides and other agrochemical products'
  },
  '20301': {
    value:
      'Manufacture of paints, varnishes and similar coatings, mastics and sealants'
  },
  '20302': { value: 'Manufacture of printing ink' },
  '20411': { value: 'Manufacture of soap and detergents' },
  '20412': { value: 'Manufacture of cleaning and polishing preparations' },
  '20420': { value: 'Manufacture of perfumes and toilet preparations' },
  '20510': { value: 'Manufacture of explosives' },
  '20520': { value: 'Manufacture of glues' },
  '20530': { value: 'Manufacture of essential oils' },
  '20590': { value: 'Manufacture of other chemical products n.e.c.' },
  '20600': { value: 'Manufacture of man-made fibres' },
  '21100': { value: 'Manufacture of basic pharmaceutical products' },
  '21200': { value: 'Manufacture of pharmaceutical preparations' },
  '22110': {
    value:
      'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres'
  },
  '22190': { value: 'Manufacture of other rubber products' },
  '22210': {
    value: 'Manufacture of plastic plates, sheets, tubes and profiles'
  },
  '22220': { value: 'Manufacture of plastic packing goods' },
  '22230': { value: 'Manufacture of builders ware of plastic' },
  '22290': { value: 'Manufacture of other plastic products' },
  '23110': { value: 'Manufacture of flat glass' },
  '23120': { value: 'Shaping and processing of flat glass' },
  '23130': { value: 'Manufacture of hollow glass' },
  '23140': { value: 'Manufacture of glass fibres' },
  '23190': {
    value:
      'Manufacture and processing of other glass, including technical glassware'
  },
  '23200': { value: 'Manufacture of refractory products' },
  '23310': { value: 'Manufacture of ceramic tiles and flags' },
  '23320': {
    value:
      'Manufacture of bricks, tiles and construction products, in baked clay'
  },
  '23410': {
    value: 'Manufacture of ceramic household and ornamental articles'
  },
  '23420': { value: 'Manufacture of ceramic sanitary fixtures' },
  '23430': {
    value: 'Manufacture of ceramic insulators and insulating fittings'
  },
  '23440': { value: 'Manufacture of other technical ceramic products' },
  '23490': { value: 'Manufacture of other ceramic products n.e.c.' },
  '23510': { value: 'Manufacture of cement' },
  '23520': { value: 'Manufacture of lime and plaster' },
  '23610': {
    value: 'Manufacture of concrete products for construction purposes'
  },
  '23620': {
    value: 'Manufacture of plaster products for construction purposes'
  },
  '23630': { value: 'Manufacture of ready-mixed concrete' },
  '23640': { value: 'Manufacture of mortars' },
  '23650': { value: 'Manufacture of fibre cement' },
  '23690': {
    value: 'Manufacture of other articles of concrete, plaster and cement'
  },
  '23700': { value: 'Cutting, shaping and finishing of stone' },
  '23910': { value: 'Production of abrasive products' },
  '23990': {
    value: 'Manufacture of other non-metallic mineral products n.e.c.'
  },
  '24100': { value: 'Manufacture of basic iron and steel and of ferro-alloys' },
  '24200': {
    value:
      'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel'
  },
  '24310': { value: 'Cold drawing of bars' },
  '24320': { value: 'Cold rolling of narrow strip' },
  '24330': { value: 'Cold forming or folding' },
  '24340': { value: 'Cold drawing of wire' },
  '24410': { value: 'Precious metals production' },
  '24420': { value: 'Aluminium production' },
  '24430': { value: 'Lead, zinc and tin production' },
  '24440': { value: 'Copper production' },
  '24450': { value: 'Other non-ferrous metal production' },
  '24460': { value: 'Processing of nuclear fuel' },
  '24510': { value: 'Casting of iron' },
  '24520': { value: 'Casting of steel' },
  '24530': { value: 'Casting of light metals' },
  '24540': { value: 'Casting of other non-ferrous metals' },
  '25110': { value: 'Manufacture of metal structures and parts of structures' },
  '25120': { value: 'Manufacture of doors and windows of metal' },
  '25210': { value: 'Manufacture of central heating radiators and boilers' },
  '25290': {
    value: 'Manufacture of other tanks, reservoirs and containers of metal'
  },
  '25300': {
    value:
      'Manufacture of steam generators, except central heating hot water boilers'
  },
  '25400': { value: 'Manufacture of weapons and ammunition' },
  '25500': {
    value:
      'Forging, pressing, stamping and roll-forming of metal; powder metallurgy'
  },
  '25610': { value: 'Treatment and coating of metals' },
  '25620': { value: 'Machining' },
  '25710': { value: 'Manufacture of cutlery' },
  '25720': { value: 'Manufacture of locks and hinges' },
  '25730': { value: 'Manufacture of tools' },
  '25910': { value: 'Manufacture of steel drums and similar containers' },
  '25920': { value: 'Manufacture of light metal packaging' },
  '25930': { value: 'Manufacture of wire products, chain and springs' },
  '25940': { value: 'Manufacture of fasteners and screw machine products' },
  '25990': { value: 'Manufacture of other fabricated metal products n.e.c.' },
  '26110': { value: 'Manufacture of electronic components' },
  '26120': { value: 'Manufacture of loaded electronic boards' },
  '26200': { value: 'Manufacture of computers and peripheral equipment' },
  '26301': {
    value: 'Manufacture of telegraph and telephone apparatus and equipment'
  },
  '26309': {
    value:
      'Manufacture of communication equipment other than telegraph, and telephone apparatus and equipment'
  },
  '26400': { value: 'Manufacture of consumer electronics' },
  '26511': {
    value:
      'Manufacture of electronic measuring, testing etc. equipment, not for industrial process control'
  },
  '26512': {
    value: 'Manufacture of electronic industrial process control equipment'
  },
  '26513': {
    value:
      'Manufacture of non-electronic measuring, testing etc. equipment, not for industrial process control'
  },
  '26514': {
    value: 'Manufacture of non-electronic industrial process control equipment'
  },
  '26520': { value: 'Manufacture of watches and clocks' },
  '26600': {
    value:
      'Manufacture of irradiation, electromedical and electrotherapeutic equipment'
  },
  '26701': { value: 'Manufacture of optical precision instruments' },
  '26702': {
    value: 'Manufacture of photographic and cinematographic equipment'
  },
  '26800': { value: 'Manufacture of magnetic and optical media' },
  '27110': {
    value: 'Manufacture of electric motors, generators and transformers'
  },
  '27120': {
    value: 'Manufacture of electricity distribution and control apparatus'
  },
  '27200': { value: 'Manufacture of batteries and accumulators' },
  '27310': { value: 'Manufacture of fibre optic cables' },
  '27320': {
    value: 'Manufacture of other electronic and electric wires and cables'
  },
  '27330': { value: 'Manufacture of wiring devices' },
  '27400': { value: 'Manufacture of electric lighting equipment' },
  '27510': { value: 'Manufacture of electric domestic appliances' },
  '27520': { value: 'Manufacture of non-electric domestic appliances' },
  '27900': { value: 'Manufacture of other electrical equipment' },
  '28110': {
    value:
      'Manufacture of engines and turbines, except aircraft, vehicle and cycle engines'
  },
  '28120': { value: 'Manufacture of fluid power equipment' },
  '28131': { value: 'Manufacture of pumps' },
  '28132': { value: 'Manufacture of compressors' },
  '28140': { value: 'Manufacture of taps and valves' },
  '28150': {
    value: 'Manufacture of bearings, gears, gearing and driving elements'
  },
  '28210': { value: 'Manufacture of ovens, furnaces and furnace burners' },
  '28220': { value: 'Manufacture of lifting and handling equipment' },
  '28230': {
    value:
      'Manufacture of office machinery and equipment (except computers and peripheral equipment)'
  },
  '28240': { value: 'Manufacture of power-driven hand tools' },
  '28250': {
    value: 'Manufacture of non-domestic cooling and ventilation equipment'
  },
  '28290': { value: 'Manufacture of other general-purpose machinery n.e.c.' },
  '28301': { value: 'Manufacture of agricultural tractors' },
  '28302': {
    value:
      'Manufacture of agricultural and forestry machinery other than tractors'
  },
  '28410': { value: 'Manufacture of metal forming machinery' },
  '28490': { value: 'Manufacture of other machine tools' },
  '28910': { value: 'Manufacture of machinery for metallurgy' },
  '28921': { value: 'Manufacture of machinery for mining' },
  '28922': { value: 'Manufacture of earthmoving equipment' },
  '28923': {
    value:
      'Manufacture of equipment for concrete crushing and screening and roadworks'
  },
  '28930': {
    value: 'Manufacture of machinery for food, beverage and tobacco processing'
  },
  '28940': {
    value:
      'Manufacture of machinery for textile, apparel and leather production'
  },
  '28950': {
    value: 'Manufacture of machinery for paper and paperboard production'
  },
  '28960': { value: 'Manufacture of plastics and rubber machinery' },
  '28990': { value: 'Manufacture of other special-purpose machinery n.e.c.' },
  '29100': { value: 'Manufacture of motor vehicles' },
  '29201': {
    value:
      'Manufacture of bodies (coachwork) for motor vehicles (except caravans)'
  },
  '29202': { value: 'Manufacture of trailers and semi-trailers' },
  '29203': { value: 'Manufacture of caravans' },
  '29310': {
    value:
      'Manufacture of electrical and electronic equipment for motor vehicles and their engines'
  },
  '29320': {
    value: 'Manufacture of other parts and accessories for motor vehicles'
  },
  '30110': { value: 'Building of ships and floating structures' },
  '30120': { value: 'Building of pleasure and sporting boats' },
  '30200': { value: 'Manufacture of railway locomotives and rolling stock' },
  '30300': { value: 'Manufacture of air and spacecraft and related machinery' },
  '30400': { value: 'Manufacture of military fighting vehicles' },
  '30910': { value: 'Manufacture of motorcycles' },
  '30920': { value: 'Manufacture of bicycles and invalid carriages' },
  '30990': { value: 'Manufacture of other transport equipment n.e.c.' },
  '31010': { value: 'Manufacture of office and shop furniture' },
  '31020': { value: 'Manufacture of kitchen furniture' },
  '31030': { value: 'Manufacture of mattresses' },
  '31090': { value: 'Manufacture of other furniture' },
  '32110': { value: 'Striking of coins' },
  '32120': { value: 'Manufacture of jewellery and related articles' },
  '32130': { value: 'Manufacture of imitation jewellery and related articles' },
  '32200': { value: 'Manufacture of musical instruments' },
  '32300': { value: 'Manufacture of sports goods' },
  '32401': { value: 'Manufacture of professional and arcade games and toys' },
  '32409': { value: 'Manufacture of other games and toys, n.e.c.' },
  '32500': {
    value: 'Manufacture of medical and dental instruments and supplies'
  },
  '32910': { value: 'Manufacture of brooms and brushes' },
  '32990': { value: 'Other manufacturing n.e.c.' },
  '33110': { value: 'Repair of fabricated metal products' },
  '33120': { value: 'Repair of machinery' },
  '33130': { value: 'Repair of electronic and optical equipment' },
  '33140': { value: 'Repair of electrical equipment' },
  '33150': { value: 'Repair and maintenance of ships and boats' },
  '33160': { value: 'Repair and maintenance of aircraft and spacecraft' },
  '33170': {
    value: 'Repair and maintenance of other transport equipment n.e.c.'
  },
  '33190': { value: 'Repair of other equipment' },
  '33200': { value: 'Installation of industrial machinery and equipment' },
  '35110': { value: 'Production of electricity' },
  '35120': { value: 'Transmission of electricity' },
  '35130': { value: 'Distribution of electricity' },
  '35140': { value: 'Trade of electricity' },
  '35210': { value: 'Manufacture of gas' },
  '35220': { value: 'Distribution of gaseous fuels through mains' },
  '35230': { value: 'Trade of gas through mains' },
  '35300': { value: 'Steam and air conditioning supply' },
  '36000': { value: 'Water collection, treatment and supply' },
  '37000': { value: 'Sewerage' },
  '38110': { value: 'Collection of non-hazardous waste' },
  '38120': { value: 'Collection of hazardous waste' },
  '38210': { value: 'Treatment and disposal of non-hazardous waste' },
  '38220': { value: 'Treatment and disposal of hazardous waste' },
  '38310': { value: 'Dismantling of wrecks' },
  '38320': { value: 'Recovery of sorted materials' },
  '39000': {
    value: 'Remediation activities and other waste management services'
  },
  '41100': { value: 'Development of building projects' },
  '41201': { value: 'Construction of commercial buildings' },
  '41202': { value: 'Construction of domestic buildings' },
  '42110': { value: 'Construction of roads and motorways' },
  '42120': { value: 'Construction of railways and underground railways' },
  '42130': { value: 'Construction of bridges and tunnels' },
  '42210': { value: 'Construction of utility projects for fluids' },
  '42220': {
    value:
      'Construction of utility projects for electricity and telecommunications'
  },
  '42910': { value: 'Construction of water projects' },
  '42990': { value: 'Construction of other civil engineering projects n.e.c.' },
  '43110': { value: 'Demolition' },
  '43120': { value: 'Site preparation' },
  '43130': { value: 'Test drilling and boring' },
  '43210': { value: 'Electrical installation' },
  '43220': { value: 'Plumbing, heat and air-conditioning installation' },
  '43290': { value: 'Other construction installation' },
  '43310': { value: 'Plastering' },
  '43320': { value: 'Joinery installation' },
  '43330': { value: 'Floor and wall covering' },
  '43341': { value: 'Painting' },
  '43342': { value: 'Glazing' },
  '43390': { value: 'Other building completion and finishing' },
  '43910': { value: 'Roofing activities' },
  '43991': { value: 'Scaffold erection' },
  '43999': { value: 'Other specialised construction activities n.e.c.' },
  '45111': { value: 'Sale of new cars and light motor vehicles' },
  '45112': { value: 'Sale of used cars and light motor vehicles' },
  '45190': { value: 'Sale of other motor vehicles' },
  '45200': { value: 'Maintenance and repair of motor vehicles' },
  '45310': { value: 'Wholesale trade of motor vehicle parts and accessories' },
  '45320': { value: 'Retail trade of motor vehicle parts and accessories' },
  '45400': {
    value:
      'Sale, maintenance and repair of motorcycles and related parts and accessories'
  },
  '46110': {
    value:
      'Agents selling agricultural raw materials, livestock, textile raw materials and semi-finished goods'
  },
  '46120': {
    value:
      'Agents involved in the sale of fuels, ores, metals and industrial chemicals'
  },
  '46130': {
    value: 'Agents involved in the sale of timber and building materials'
  },
  '46140': {
    value:
      'Agents involved in the sale of machinery, industrial equipment, ships and aircraft'
  },
  '46150': {
    value:
      'Agents involved in the sale of furniture, household goods, hardware and ironmongery'
  },
  '46160': {
    value:
      'Agents involved in the sale of textiles, clothing, fur, footwear and leather goods'
  },
  '46170': {
    value: 'Agents involved in the sale of food, beverages and tobacco'
  },
  '46180': {
    value: 'Agents specialised in the sale of other particular products'
  },
  '46190': { value: 'Agents involved in the sale of a variety of goods' },
  '46210': {
    value: 'Wholesale of grain, unmanufactured tobacco, seeds and animal feeds'
  },
  '46220': { value: 'Wholesale of flowers and plants' },
  '46230': { value: 'Wholesale of live animals' },
  '46240': { value: 'Wholesale of hides, skins and leather' },
  '46310': { value: 'Wholesale of fruit and vegetables' },
  '46320': { value: 'Wholesale of meat and meat products' },
  '46330': {
    value: 'Wholesale of dairy products, eggs and edible oils and fats'
  },
  '46341': {
    value:
      'Wholesale of fruit and vegetable juices, mineral water and soft drinks'
  },
  '46342': {
    value: 'Wholesale of wine, beer, spirits and other alcoholic beverages'
  },
  '46350': { value: 'Wholesale of tobacco products' },
  '46360': {
    value: 'Wholesale of sugar and chocolate and sugar confectionery'
  },
  '46370': { value: 'Wholesale of coffee, tea, cocoa and spices' },
  '46380': {
    value: 'Wholesale of other food, including fish, crustaceans and molluscs'
  },
  '46390': {
    value: 'Non-specialised wholesale of food, beverages and tobacco'
  },
  '46410': { value: 'Wholesale of textiles' },
  '46420': { value: 'Wholesale of clothing and footwear' },
  '46431': {
    value:
      'Wholesale of audio tapes, records, CDs and video tapes and the equipment on which these are played'
  },
  '46439': {
    value:
      "Wholesale of radio, television goods & electrical household appliances (other than records, tapes, CD's & video tapes and the equipment used for playing them)"
  },
  '46440': { value: 'Wholesale of china and glassware and cleaning materials' },
  '46450': { value: 'Wholesale of perfume and cosmetics' },
  '46460': { value: 'Wholesale of pharmaceutical goods' },
  '46470': { value: 'Wholesale of furniture, carpets and lighting equipment' },
  '46480': { value: 'Wholesale of watches and jewellery' },
  '46491': { value: 'Wholesale of musical instruments' },
  '46499': {
    value: 'Wholesale of household goods (other than musical instruments) n.e.c'
  },
  '46510': {
    value: 'Wholesale of computers, computer peripheral equipment and software'
  },
  '46520': {
    value: 'Wholesale of electronic and telecommunications equipment and parts'
  },
  '46610': {
    value: 'Wholesale of agricultural machinery, equipment and supplies'
  },
  '46620': { value: 'Wholesale of machine tools' },
  '46630': {
    value: 'Wholesale of mining, construction and civil engineering machinery'
  },
  '46640': {
    value:
      'Wholesale of machinery for the textile industry and of sewing and knitting machines'
  },
  '46650': { value: 'Wholesale of office furniture' },
  '46660': { value: 'Wholesale of other office machinery and equipment' },
  '46690': { value: 'Wholesale of other machinery and equipment' },
  '46711': { value: 'Wholesale of petroleum and petroleum products' },
  '46719': { value: 'Wholesale of other fuels and related products' },
  '46720': { value: 'Wholesale of metals and metal ores' },
  '46730': {
    value: 'Wholesale of wood, construction materials and sanitary equipment'
  },
  '46740': {
    value: 'Wholesale of hardware, plumbing and heating equipment and supplies'
  },
  '46750': { value: 'Wholesale of chemical products' },
  '46760': { value: 'Wholesale of other intermediate products' },
  '46770': { value: 'Wholesale of waste and scrap' },
  '46900': { value: 'Non-specialised wholesale trade' },
  '47110': {
    value:
      'Retail sale in non-specialised stores with food, beverages or tobacco predominating'
  },
  '47190': { value: 'Other retail sale in non-specialised stores' },
  '47210': {
    value: 'Retail sale of fruit and vegetables in specialised stores'
  },
  '47220': {
    value: 'Retail sale of meat and meat products in specialised stores'
  },
  '47230': {
    value: 'Retail sale of fish, crustaceans and molluscs in specialised stores'
  },
  '47240': {
    value:
      'Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores'
  },
  '47250': { value: 'Retail sale of beverages in specialised stores' },
  '47260': { value: 'Retail sale of tobacco products in specialised stores' },
  '47290': { value: 'Other retail sale of food in specialised stores' },
  '47300': { value: 'Retail sale of automotive fuel in specialised stores' },
  '47410': {
    value:
      'Retail sale of computers, peripheral units and software in specialised stores'
  },
  '47421': { value: 'Retail sale of mobile telephones' },
  '47429': {
    value:
      'Retail sale of telecommunications equipment other than mobile telephones'
  },
  '47430': {
    value: 'Retail sale of audio and video equipment in specialised stores'
  },
  '47510': { value: 'Retail sale of textiles in specialised stores' },
  '47520': {
    value: 'Retail sale of hardware, paints and glass in specialised stores'
  },
  '47530': {
    value:
      'Retail sale of carpets, rugs, wall and floor coverings in specialised stores'
  },
  '47540': {
    value:
      'Retail sale of electrical household appliances in specialised stores'
  },
  '47591': { value: 'Retail sale of musical instruments and scores' },
  '47599': {
    value:
      'Retail of furniture, lighting, and similar (not musical instruments or scores) in specialised store'
  },
  '47610': { value: 'Retail sale of books in specialised stores' },
  '47620': {
    value: 'Retail sale of newspapers and stationery in specialised stores'
  },
  '47630': {
    value: 'Retail sale of music and video recordings in specialised stores'
  },
  '47640': {
    value:
      'Retail sale of sports goods, fishing gear, camping goods, boats and bicycles'
  },
  '47650': { value: 'Retail sale of games and toys in specialised stores' },
  '47710': { value: 'Retail sale of clothing in specialised stores' },
  '47721': { value: 'Retail sale of footwear in specialised stores' },
  '47722': { value: 'Retail sale of leather goods in specialised stores' },
  '47730': { value: 'Dispensing chemist in specialised stores' },
  '47741': { value: 'Retail sale of hearing aids' },
  '47749': {
    value:
      'Retail sale of medical and orthopaedic goods in specialised stores (not incl. hearing aids) n.e.c.'
  },
  '47750': {
    value: 'Retail sale of cosmetic and toilet articles in specialised stores'
  },
  '47760': {
    value:
      'Retail sale of flowers, plants, seeds, fertilizers, pet animals and pet food in specialised stores'
  },
  '47770': {
    value: 'Retail sale of watches and jewellery in specialised stores'
  },
  '47781': { value: 'Retail sale in commercial art galleries' },
  '47782': { value: 'Retail sale by opticians' },
  '47789': {
    value:
      'Other retail sale of new goods in specialised stores (not commercial art galleries and opticians)'
  },
  '47791': {
    value: 'Retail sale of antiques including antique books in stores'
  },
  '47799': {
    value:
      'Retail sale of other second-hand goods in stores (not incl. antiques)'
  },
  '47810': {
    value:
      'Retail sale via stalls and markets of food, beverages and tobacco products'
  },
  '47820': {
    value:
      'Retail sale via stalls and markets of textiles, clothing and footwear'
  },
  '47890': { value: 'Retail sale via stalls and markets of other goods' },
  '47910': { value: 'Retail sale via mail order houses or via Internet' },
  '47990': { value: 'Other retail sale not in stores, stalls or markets' },
  '49100': { value: 'Passenger rail transport, interurban' },
  '49200': { value: 'Freight rail transport' },
  '49311': {
    value:
      'Urban and suburban passenger railway transportation by underground, metro and similar systems'
  },
  '49319': {
    value:
      'Other urban, suburban or metropolitan passenger land transport (not underground, metro or similar)'
  },
  '49320': { value: 'Taxi operation' },
  '49390': { value: 'Other passenger land transport' },
  '49410': { value: 'Freight transport by road' },
  '49420': { value: 'Removal services' },
  '49500': { value: 'Transport via pipeline' },
  '50100': { value: 'Sea and coastal passenger water transport' },
  '50200': { value: 'Sea and coastal freight water transport' },
  '50300': { value: 'Inland passenger water transport' },
  '50400': { value: 'Inland freight water transport' },
  '51101': { value: 'Scheduled passenger air transport' },
  '51102': { value: 'Non-scheduled passenger air transport' },
  '51210': { value: 'Freight air transport' },
  '51220': { value: 'Space transport' },
  '52101': {
    value:
      'Operation of warehousing and storage facilities for water transport activities'
  },
  '52102': {
    value:
      'Operation of warehousing and storage facilities for air transport activities'
  },
  '52103': {
    value:
      'Operation of warehousing and storage facilities for land transport activities'
  },
  '52211': { value: 'Operation of rail freight terminals' },
  '52212': {
    value: 'Operation of rail passenger facilities at railway stations'
  },
  '52213': {
    value:
      'Operation of bus and coach passenger facilities at bus and coach stations'
  },
  '52219': {
    value: 'Other service activities incidental to land transportation, n.e.c.'
  },
  '52220': { value: 'Service activities incidental to water transportation' },
  '52230': { value: 'Service activities incidental to air transportation' },
  '52241': { value: 'Cargo handling for water transport activities' },
  '52242': { value: 'Cargo handling for air transport activities' },
  '52243': { value: 'Cargo handling for land transport activities' },
  '52290': { value: 'Other transportation support activities' },
  '53100': { value: 'Postal activities under universal service obligation' },
  '53201': { value: 'Licensed carriers' },
  '53202': { value: 'Unlicensed carriers' },
  '55100': { value: 'Hotels and similar accommodation' },
  '55201': { value: 'Holiday centres and villages' },
  '55202': { value: 'Youth hostels' },
  '55209': { value: 'Other holiday and other collective accommodation' },
  '55300': {
    value: 'Recreational vehicle parks, trailer parks and camping grounds'
  },
  '55900': { value: 'Other accommodation' },
  '56101': { value: 'Licenced restaurants' },
  '56102': { value: 'Unlicenced restaurants and cafes' },
  '56103': { value: 'Take-away food shops and mobile food stands' },
  '56210': { value: 'Event catering activities' },
  '56290': { value: 'Other food services' },
  '56301': { value: 'Licenced clubs' },
  '56302': { value: 'Public houses and bars' },
  '58110': { value: 'Book publishing' },
  '58120': { value: 'Publishing of directories and mailing lists' },
  '58130': { value: 'Publishing of newspapers' },
  '58141': { value: 'Publishing of learned journals' },
  '58142': {
    value: 'Publishing of consumer and business journals and periodicals'
  },
  '58190': { value: 'Other publishing activities' },
  '58210': { value: 'Publishing of computer games' },
  '58290': { value: 'Other software publishing' },
  '59111': { value: 'Motion picture production activities' },
  '59112': { value: 'Video production activities' },
  '59113': { value: 'Television programme production activities' },
  '59120': {
    value:
      'Motion picture, video and television programme post-production activities'
  },
  '59131': { value: 'Motion picture distribution activities' },
  '59132': { value: 'Video distribution activities' },
  '59133': { value: 'Television programme distribution activities' },
  '59140': { value: 'Motion picture projection activities' },
  '59200': { value: 'Sound recording and music publishing activities' },
  '60100': { value: 'Radio broadcasting' },
  '60200': { value: 'Television programming and broadcasting activities' },
  '61100': { value: 'Wired telecommunications activities' },
  '61200': { value: 'Wireless telecommunications activities' },
  '61300': { value: 'Satellite telecommunications activities' },
  '61900': { value: 'Other telecommunications activities' },
  '62011': {
    value:
      'Ready-made interactive leisure and entertainment software development'
  },
  '62012': { value: 'Business and domestic software development' },
  '62020': { value: 'Information technology consultancy activities' },
  '62030': { value: 'Computer facilities management activities' },
  '62090': { value: 'Other information technology service activities' },
  '63110': { value: 'Data processing, hosting and related activities' },
  '63120': { value: 'Web portals' },
  '63910': { value: 'News agency activities' },
  '63990': { value: 'Other information service activities n.e.c.' },
  '64110': { value: 'Central banking' },
  '64191': { value: 'Banks' },
  '64192': { value: 'Building societies' },
  '64201': { value: 'Activities of agricultural holding companies' },
  '64202': { value: 'Activities of production holding companies' },
  '64203': { value: 'Activities of construction holding companies' },
  '64204': { value: 'Activities of distribution holding companies' },
  '64205': { value: 'Activities of financial services holding companies' },
  '64209': { value: 'Activities of other holding companies n.e.c.' },
  '64301': { value: 'Activities of investment trusts' },
  '64302': { value: 'Activities of unit trusts' },
  '64303': { value: 'Activities of venture and development capital companies' },
  '64304': { value: 'Activities of open-ended investment companies' },
  '64305': { value: 'Activities of property unit trusts' },
  '64306': { value: 'Activities of real estate investment trusts' },
  '64910': { value: 'Financial leasing' },
  '64921': {
    value:
      'Credit granting by non-deposit taking finance houses and other specialist consumer credit grantors'
  },
  '64922': { value: 'Activities of mortgage finance companies' },
  '64929': { value: 'Other credit granting n.e.c.' },
  '64991': { value: 'Security dealing on own account' },
  '64992': { value: 'Factoring' },
  '64999': { value: 'Financial intermediation not elsewhere classified' },
  '65110': { value: 'Life insurance' },
  '65120': { value: 'Non-life insurance' },
  '65201': { value: 'Life reinsurance' },
  '65202': { value: 'Non-life reinsurance' },
  '65300': { value: 'Pension funding' },
  '66110': { value: 'Administration of financial markets' },
  '66120': { value: 'Security and commodity contracts dealing activities' },
  '66190': { value: 'Activities auxiliary to financial intermediation n.e.c.' },
  '66210': { value: 'Risk and damage evaluation' },
  '66220': { value: 'Activities of insurance agents and brokers' },
  '66290': {
    value: 'Other activities auxiliary to insurance and pension funding'
  },
  '66300': { value: 'Fund management activities' },
  '68100': { value: 'Buying and selling of own real estate' },
  '68201': {
    value: 'Renting and operating of Housing Association real estate'
  },
  '68202': {
    value: 'Letting and operating of conference and exhibition centres'
  },
  '68209': {
    value: 'Other letting and operating of own or leased real estate'
  },
  '68310': { value: 'Real estate agencies' },
  '68320': { value: 'Management of real estate on a fee or contract basis' },
  '69101': { value: 'Barristers at law' },
  '69102': { value: 'Solicitors' },
  '69109': {
    value:
      'Activities of patent and copyright agents; other legal activities n.e.c.'
  },
  '69201': { value: 'Accounting and auditing activities' },
  '69202': { value: 'Bookkeeping activities' },
  '69203': { value: 'Tax consultancy' },
  '70100': { value: 'Activities of head offices' },
  '70210': { value: 'Public relations and communications activities' },
  '70221': { value: 'Financial management' },
  '70229': {
    value: 'Management consultancy activities other than financial management'
  },
  '71111': { value: 'Architectural activities' },
  '71112': { value: 'Urban planning and landscape architectural activities' },
  '71121': {
    value: 'Engineering design activities for industrial process and production'
  },
  '71122': {
    value: 'Engineering related scientific and technical consulting activities'
  },
  '71129': { value: 'Other engineering activities' },
  '71200': { value: 'Technical testing and analysis' },
  '72110': { value: 'Research and experimental development on biotechnology' },
  '72190': {
    value:
      'Other research and experimental development on natural sciences and engineering'
  },
  '72200': {
    value:
      'Research and experimental development on social sciences and humanities'
  },
  '73110': { value: 'Advertising agencies' },
  '73120': { value: 'Media representation services' },
  '73200': { value: 'Market research and public opinion polling' },
  '74100': { value: 'specialised design activities' },
  '74201': { value: 'Portrait photographic activities' },
  '74202': { value: 'Other specialist photography' },
  '74203': { value: 'Film processing' },
  '74209': { value: 'Photographic activities not elsewhere classified' },
  '74300': { value: 'Translation and interpretation activities' },
  '74901': { value: 'Environmental consulting activities' },
  '74902': { value: 'Quantity surveying activities' },
  '74909': {
    value: 'Other professional, scientific and technical activities n.e.c.'
  },
  '74990': { value: 'Non-trading company non trading' },
  '75000': { value: 'Veterinary activities' },
  '77110': { value: 'Renting and leasing of cars and light motor vehicles' },
  '77120': { value: 'Renting and leasing of trucks and other heavy vehicles' },
  '77210': { value: 'Renting and leasing of recreational and sports goods' },
  '77220': { value: 'Renting of video tapes and disks' },
  '77291': { value: 'Renting and leasing of media entertainment equipment' },
  '77299': {
    value: 'Renting and leasing of other personal and household goods'
  },
  '77310': {
    value: 'Renting and leasing of agricultural machinery and equipment'
  },
  '77320': {
    value:
      'Renting and leasing of construction and civil engineering machinery and equipment'
  },
  '77330': {
    value:
      'Renting and leasing of office machinery and equipment (including computers)'
  },
  '77341': {
    value: 'Renting and leasing of passenger water transport equipment'
  },
  '77342': {
    value: 'Renting and leasing of freight water transport equipment'
  },
  '77351': {
    value: 'Renting and leasing of air passenger transport equipment'
  },
  '77352': { value: 'Renting and leasing of freight air transport equipment' },
  '77390': {
    value:
      'Renting and leasing of other machinery, equipment and tangible goods n.e.c.'
  },
  '77400': {
    value:
      'Leasing of intellectual property and similar products, except copyright works'
  },
  '78101': {
    value: 'Motion picture, television and other theatrical casting activities'
  },
  '78109': { value: 'Other activities of employment placement agencies' },
  '78200': { value: 'Temporary employment agency activities' },
  '78300': {
    value:
      'Human resources provision and management of human resources functions'
  },
  '79110': { value: 'Travel agency activities' },
  '79120': { value: 'Tour operator activities' },
  '79901': { value: 'Activities of tourist guides' },
  '79909': { value: 'Other reservation service activities n.e.c.' },
  '80100': { value: 'Private security activities' },
  '80200': { value: 'Security systems service activities' },
  '80300': { value: 'Investigation activities' },
  '81100': { value: 'Combined facilities support activities' },
  '81210': { value: 'General cleaning of buildings' },
  '81221': { value: 'Window cleaning services' },
  '81222': { value: 'Specialised cleaning services' },
  '81223': { value: 'Furnace and chimney cleaning services' },
  '81229': { value: 'Other building and industrial cleaning activities' },
  '81291': { value: 'Disinfecting and exterminating services' },
  '81299': { value: 'Other cleaning services' },
  '81300': { value: 'Landscape service activities' },
  '82110': { value: 'Combined office administrative service activities' },
  '82190': {
    value:
      'Photocopying, document preparation and other specialised office support activities'
  },
  '82200': { value: 'Activities of call centres' },
  '82301': { value: 'Activities of exhibition and fair organisers' },
  '82302': { value: 'Activities of conference organisers' },
  '82911': { value: 'Activities of collection agencies' },
  '82912': { value: 'Activities of credit bureaus' },
  '82920': { value: 'Packaging activities' },
  '82990': { value: 'Other business support service activities n.e.c.' },
  '84110': { value: 'General public administration activities' },
  '84120': {
    value:
      'Regulation of health care, education, cultural and other social services, not incl. social security'
  },
  '84130': {
    value:
      'Regulation of and contribution to more efficient operation of businesses'
  },
  '84210': { value: 'Foreign affairs' },
  '84220': { value: 'Defence activities' },
  '84230': { value: 'Justice and judicial activities' },
  '84240': { value: 'Public order and safety activities' },
  '84250': { value: 'Fire service activities' },
  '84300': { value: 'Compulsory social security activities' },
  '85100': { value: 'Pre-primary education' },
  '85200': { value: 'Primary education' },
  '85310': { value: 'General secondary education' },
  '85320': { value: 'Technical and vocational secondary education' },
  '85410': { value: 'Post-secondary non-tertiary education' },
  '85421': { value: 'First-degree level higher education' },
  '85422': { value: 'Post-graduate level higher education' },
  '85510': { value: 'Sports and recreation education' },
  '85520': { value: 'Cultural education' },
  '85530': { value: 'Driving school activities' },
  '85590': { value: 'Other education n.e.c.' },
  '85600': { value: 'Educational support services' },
  '86101': { value: 'Hospital activities' },
  '86102': { value: 'Medical nursing home activities' },
  '86210': { value: 'General medical practice activities' },
  '86220': { value: 'Specialists medical practice activities' },
  '86230': { value: 'Dental practice activities' },
  '86900': { value: 'Other human health activities' },
  '87100': { value: 'Residential nursing care facilities' },
  '87200': {
    value:
      'Residential care activities for learning difficulties, mental health and substance abuse'
  },
  '87300': {
    value: 'Residential care activities for the elderly and disabled'
  },
  '87900': { value: 'Other residential care activities n.e.c.' },
  '88100': {
    value:
      'Social work activities without accommodation for the elderly and disabled'
  },
  '88910': { value: 'Child day-care activities' },
  '88990': {
    value: 'Other social work activities without accommodation n.e.c.'
  },
  '90010': { value: 'Performing arts' },
  '90020': { value: 'Support activities to performing arts' },
  '90030': { value: 'Artistic creation' },
  '90040': { value: 'Operation of arts facilities' },
  '91011': { value: 'Library activities' },
  '91012': { value: 'Archives activities' },
  '91020': { value: 'Museums activities' },
  '91030': {
    value:
      'Operation of historical sites and buildings and similar visitor attractions'
  },
  '91040': {
    value: 'Botanical and zoological gardens and nature reserves activities'
  },
  '92000': { value: 'Gambling and betting activities' },
  '93110': { value: 'Operation of sports facilities' },
  '93120': { value: 'Activities of sport clubs' },
  '93130': { value: 'Fitness facilities' },
  '93191': { value: 'Activities of racehorse owners' },
  '93199': { value: 'Other sports activities' },
  '93210': { value: 'Activities of amusement parks and theme parks' },
  '93290': { value: 'Other amusement and recreation activities n.e.c.' },
  '94110': {
    value: 'Activities of business and employers membership organisations'
  },
  '94120': { value: 'Activities of professional membership organisations' },
  '94200': { value: 'Activities of trade unions' },
  '94910': { value: 'Activities of religious organisations' },
  '94920': { value: 'Activities of political organisations' },
  '94990': { value: 'Activities of other membership organisations n.e.c.' },
  '95110': { value: 'Repair of computers and peripheral equipment' },
  '95120': { value: 'Repair of communication equipment' },
  '95210': { value: 'Repair of consumer electronics' },
  '95220': {
    value: 'Repair of household appliances and home and garden equipment'
  },
  '95230': { value: 'Repair of footwear and leather goods' },
  '95240': { value: 'Repair of furniture and home furnishings' },
  '95250': { value: 'Repair of watches, clocks and jewellery' },
  '95290': { value: 'Repair of personal and household goods n.e.c.' },
  '96010': { value: 'Washing and (dry-)cleaning of textile and fur products' },
  '96020': { value: 'Hairdressing and other beauty treatment' },
  '96030': { value: 'Funeral and related activities' },
  '96040': { value: 'Physical well-being activities' },
  '96090': { value: 'Other service activities n.e.c.' },
  '97000': {
    value: 'Activities of households as employers of domestic personnel'
  },
  '98000': { value: 'Residents property management' },
  '98100': {
    value:
      'Undifferentiated goods-producing activities of private households for own use'
  },
  '98200': {
    value:
      'Undifferentiated service-producing activities of private households for own use'
  },
  '99000': { value: 'Activities of extraterritorial organisations and bodies' },
  '99999': { value: 'Dormant Company' },
  '01110': {
    value: 'Growing of cereals (except rice), leguminous crops and oil seeds'
  },
  '01120': { value: 'Growing of rice' },
  '01130': { value: 'Growing of vegetables and melons, roots and tubers' },
  '01140': { value: 'Growing of sugar cane' },
  '01150': { value: 'Growing of tobacco' },
  '01160': { value: 'Growing of fibre crops' },
  '01190': { value: 'Growing of other non-perennial crops' },
  '01210': { value: 'Growing of grapes' },
  '01220': { value: 'Growing of tropical and subtropical fruits' },
  '01230': { value: 'Growing of citrus fruits' },
  '01240': { value: 'Growing of pome fruits and stone fruits' },
  '01250': { value: 'Growing of other tree and bush fruits and nuts' },
  '01260': { value: 'Growing of oleaginous fruits' },
  '01270': { value: 'Growing of beverage crops' },
  '01280': {
    value: 'Growing of spices, aromatic, drug and pharmaceutical crops'
  },
  '01290': { value: 'Growing of other perennial crops' },
  '01300': { value: 'Plant propagation' },
  '01410': { value: 'Raising of dairy cattle' },
  '01420': { value: 'Raising of other cattle and buffaloes' },
  '01430': { value: 'Raising of horses and other equines' },
  '01440': { value: 'Raising of camels and camelids' },
  '01450': { value: 'Raising of sheep and goats' },
  '01460': { value: 'Raising of swine/pigs' },
  '01470': { value: 'Raising of poultry' },
  '01490': { value: 'Raising of other animals' },
  '01500': { value: 'Mixed farming' },
  '01610': { value: 'Support activities for crop production' },
  '01621': { value: 'Farm animal boarding and care' },
  '01629': {
    value:
      'Support activities for animal production (other than farm animal boarding and care) n.e.c.'
  },
  '01630': { value: 'Post-harvest crop activities' },
  '01640': { value: 'Seed processing for propagation' },
  '01700': { value: 'Hunting, trapping and related service activities' },
  '02100': { value: 'Silviculture and other forestry activities' },
  '02200': { value: 'Logging' },
  '02300': { value: 'Gathering of wild growing non-wood products' },
  '02400': { value: 'Support services to forestry' },
  '03110': { value: 'Marine fishing' },
  '03120': { value: 'Freshwater fishing' },
  '03210': { value: 'Marine aquaculture' },
  '03220': { value: 'Freshwater aquaculture' },
  '05101': { value: 'Deep coal mines' },
  '05102': { value: 'Open cast coal working' },
  '05200': { value: 'Mining of lignite' },
  '06100': { value: 'Extraction of crude petroleum' },
  '06200': { value: 'Extraction of natural gas' },
  '07100': { value: 'Mining of iron ores' },
  '07210': { value: 'Mining of uranium and thorium ores' },
  '07290': { value: 'Mining of other non-ferrous metal ores' },
  '08110': {
    value:
      'Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate'
  },
  '08120': {
    value: 'Operation of gravel and sand pits; mining of clays and kaolin'
  },
  '08910': { value: 'Mining of chemical and fertilizer minerals' },
  '08920': { value: 'Extraction of peat' },
  '08930': { value: 'Extraction of salt' },
  '08990': { value: 'Other mining and quarrying n.e.c.' },
  '09100': { value: 'Support activities for petroleum and natural gas mining' },
  '09900': { value: 'Support activities for other mining and quarrying' }
}
