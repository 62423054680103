<template>
  <ChoiceBox 
    :choices="choices"
    :custom-box-size="'sm'"
    :custom-choice="true"
    @clicked="selectChoice"
  />
</template>

<script>
import ChoiceBox from '@/components/question/ChoiceBox'
export default {
  name: 'AssetsQuestion',
  components: {
    ChoiceBox
  },
  data () {
    return {
      choices: this.$attrs.data.choices
    }
  },
  methods: {
    selectChoice (value) {
      this.$emit('input', {
        result: value
      })
    }
  }
}
</script>
